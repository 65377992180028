/* eslint-disable camelcase */
import { useEffect, useState, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Container, Dropdown, Sidebar, Menu, Icon,
} from 'semantic-ui-react';
import dynamic from 'next/dynamic';
import PageMeta from '../components/PageMeta';
import NotAuthorized from '../components/NotAuthorized';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import FluidLayout from '../components/FluidLayout';
import Feedback from '../components/Feedback';
import Footer from '../components/Footer';
import api from '../lib/api';
import roles from '../lib/roles';
import go from '../lib/go';
import hasSpecialPermission from '../lib/hasSpecialPermission';

import 'semantic-ui-css/semantic.min.css';
import 'react-big-calendar/lib/sass/styles.scss';
import './_app.scss';
import './_print.scss';
import 'react-datepicker/dist/react-datepicker.css';
// eslint-disable-next-line
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import 'react-dropzone-uploader/dist/styles.css';
import SiteAlert from '../components/SiteAlerts';

const Nav = dynamic(() => import('../components/Nav'), { ssr: false });

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const defaultRes = { ll: [] };

function checkAuthProblems(router, { authStatus, role_id: roleId, username }) {
  let authProblem = false;
  const requiresUser = [
    '/me',
    '/recertification-application',
    '/learning-center',
    '/continuing-education',
    '/job-postings',
    '/exam-committee-app',
    '/ce-providers-promo',
  ];

  // biome-ignore lint/complexity/noForEach: <explanation>
  requiresUser.forEach((path) => {
    if (router.pathname === path && !authStatus) {
      authProblem = true;
    }
  });

  if (router.pathname.startsWith('/admin')) {
    if (authStatus === true) {
      if (router.pathname.startsWith('/admin/board_app') && username === 'katrina.rush') {
        authProblem = false;
      } else if (roleId < roles.ADMIN) {
        authProblem = true;
      }
    } else {
      authProblem = true;
    }
  }

  if (hasSpecialPermission('GET', router.pathname, username)) {
    authProblem = false;
  }

  if (router.pathname.startsWith('/board/')) {
    if (authStatus === false || roleId < roles.BOARD) {
      authProblem = true;
    }
  }

  if (authProblem) {
    return true;
  }

  return false;
}

// layout wrapper for early exit content
const santaBroughtItEarly = (child) => (
  <FluidLayout>
    <Container>{child}</Container>
  </FluidLayout>
);

export default function App({ Component, pageProps }) {
  const mounted = useRef(false);
  const [loc, setLocation] = useState(defaultRes);
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [user, setUser] = useState({ authStatus: false });
  const router = useRouter();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    mounted.current = true;
    setLoading(true);

    const controller = new AbortController();
    api
      .get('user/status', { signal: controller.signal })
      .then(({ geoip = { ll: [] }, alert: a, ...usr }) => {
        if (mounted.current) {
          setLocation(geoip);
          setUser(usr);
          setAlert(a);
        }
      })
      .catch((err) => mounted.current && setError(err.message))
      .finally(() => mounted.current && setLoading(false));

    return () => {
      mounted.current = false;
      controller.abort();
    };
  }, []);

  const authProblems = checkAuthProblems(router, user);
  const {
    password, username, v2password, auth_token, role_id,
    notes, featured, featured_until, latlon_check,
    ...slimUser
  } = user;
  const requestedPage = (
    <Component
      ll={loc?.ll || []}
      user={slimUser}
      {...pageProps}
    />
  );
  const content = authProblems || requestedPage;

  if (loading) {
    return santaBroughtItEarly(<Loader />);
  }

  if (authProblems) {
    return santaBroughtItEarly(<NotAuthorized loggedIn={!!user.authStatus} />);
  }

  if (error) {
    return santaBroughtItEarly(<ErrorMessage message={error} />);
  }

  const isAdmin = router.pathname.startsWith('/admin');

  return (
    <div>
      <SiteAlert {...alert} />
      <PageMeta />
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          width="very wide"
          visible={!isAdmin && sidebarVisible}
        >
          <Container fluid textAlign="right">
            <Icon
              id="closeSidebar"
              inverted
              name="close"
              onClick={() => setSidebarVisible(false)}
            />
          </Container>
          <Dropdown item text="About" data-link="/about" onClick={go}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <i className="dropdown icon" />
                Who We Are
                <Dropdown.Menu>
                  <Dropdown.Item as="a" href="/about">
                    About Us
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="/mission-statement">
                    Mission
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="presidents-message">
                    President&rsquo;s Message
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    as="a"
                    href="/uploads/LANA Policy & Procedures Manual 2022.pdf"
                    icon="download"
                    text="Policy & Procedures"
                  />
                </Dropdown.Menu>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link className="text" href="/leadership">
                  LANA Leadership
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/announcements">
                Announcements
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/faq">
                FAQ
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item simple text="Sponsors" data-link="/sponsors" onClick={go}>
            <Dropdown.Menu>
              <Dropdown.Item href="/sponsors#premier">Premier</Dropdown.Item>
              <Dropdown.Item href="/sponsors#corporate">Corporate</Dropdown.Item>
              <Dropdown.Item href="/sponsors#institutional">Institutional</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="/sponsors/become-a-sponsor"> Become a Sponsor</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item simple text="Certification">
            <Dropdown.Menu>
              <Dropdown.Item data-link="/certification" onClick={go}>
                Why Become LANA Certified
              </Dropdown.Item>
              <Dropdown.Item>
                <i className="dropdown icon" />
                <Link className="text" href="/get-certified">
                  Get LANA Certified
                </Link>
                <Dropdown.Menu>
                  <Dropdown.Item as="a" href="/get-certified">
                    Qualifications
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="/reading-list">
                    Reading List
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="/exam-outline">
                    Exam Outline
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="/certification-application">
                    Online Application
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item
                    as="a"
                    target="_blank"
                    href="http://www.isoqualitytesting.com/locations.aspx"
                    icon="external"
                    text="Testing Sites"
                  />
                </Dropdown.Menu>
              </Dropdown.Item>

              <Dropdown.Item data-link="/recertification" onClick={go}>
                <i className="dropdown icon" />
                Recertification
                <Dropdown.Menu>
                  <Dropdown.Item as="a" href="/recertification">
                    Information
                  </Dropdown.Item>
                  <Dropdown.Item as="a" href="/recertification-application">
                    Online Application
                  </Dropdown.Item>

                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/training-programs">
                Lymphedema Training Programs
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item simple text="Members">
            <Dropdown.Menu>
              <Dropdown.Item as="a" href="/learning-center">
                LANA Learning Center
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/recertification">
                Recertification
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/resources-links">
                Resource Links
              </Dropdown.Item>
              <Dropdown.Item href="/job-postings">Job Opportunities</Dropdown.Item>

              <Dropdown.Item>
                <i className="dropdown icon" />
                <span className="text">Volunteer Opportunities</span>
                <Dropdown.Menu>
                  <Dropdown.Item href="/board-app" text="Become a Board Member" />
                  <Dropdown.Item href="/exam-committee-app" text="Join the Exam Committee" />
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/ce-providers-promo">
                LANA Member Discounts
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Header>Board of Directors</Dropdown.Header>
              <Dropdown.Item href="/board/directory">Internal Directory</Dropdown.Item>
              <Dropdown.Item href="/board/meetings">Upcoming Meetings</Dropdown.Item>
              <Dropdown.Item href="/board/minutes">Board Meeting Minutes</Dropdown.Item>
              <Dropdown.Item href="/board/report-forms">Report Forms</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item simple text="Public">
            <Dropdown.Menu>
              <Dropdown.Item as="a" href="/public">
                Why See a LANA Specialist
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/learning-center">
                Learning Center
              </Dropdown.Item>
              <Dropdown.Item as="a" href="/resources-links">
                Resource Links
              </Dropdown.Item>
              <Dropdown.Item href="/therapists">Find a LANA Specialist</Dropdown.Item>
              <Dropdown.Item href="/physicians">Find a Physician</Dropdown.Item>
              <Dropdown.Item href="/support">Support Groups</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Menu.Item>
            <Link href="/calendar">
              <div className="item">Calendar</div>
            </Link>
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher>
          {!isAdmin && (
            <Nav sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
          )}
          {content}
          <Footer exp={router.pathname === '/home'} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <Feedback user={user} />
    </div>
  );
}

/** @type {import("next").Metadata} */
export const metadata = {
  title: {
    template: '%s | LANA',
    default: 'Lymphology Association of North America',
  },
  description: 'The Lymphology Association of North America (LANA) is a non-profit corporation composed of healthcare professionals, including physicians, nurses, massage therapists, physical therapists, and occupational therapists experienced in the field of Lymphology and lymphedema. They have acknowledged the need for a national certification examination for lymphedema therapists, to test knowledge considered fundamental in the treatment of lymphedema.',
  keywords: [],
  metadataBase: new URL('https://clt-lana.org'),
  alternates: {
    canonical: '/',
  },
};
