import classNames from 'classnames';
import { Container } from 'semantic-ui-react';

const FluidLayout = ({
  children, className, imageSrc, imageSize = 'cover',
}) => {
  let hero = null;
  if (imageSrc) {
    hero = (
      <Container
        id="splash"
        fluid
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundPosition: 'center -18vmax',
          backgroundSize: imageSize,
          zIndex: 99999,
          marginTop: '-8vmin',
          maxHeight: '25vmin',
          marginBottom: '4vmin',
        }}
      />
    );
  }
  return (
    <Container fluid className={classNames(className, 'FluidLayout')}>
      <Container fluid id="pageContent">
        {hero}
        {children}
      </Container>
    </Container>
  );
};

export default FluidLayout;

/** @type {import("next").Metadata} */
export const metadata = {
  title: {
    template: '%s | LANA',
    default: 'Lymphology Association of North America',
  },
  description: 'The Lymphology Association of North America (LANA) is a non-profit corporation composed of healthcare professionals, including physicians, nurses, massage therapists, physical therapists, and occupational therapists experienced in the field of Lymphology and lymphedema. They have acknowledged the need for a national certification examination for lymphedema therapists, to test knowledge considered fundamental in the treatment of lymphedema.',
  keywords: [],
  metadataBase: new URL('https://clt-lana.org'),
  alternates: {
    canonical: '/',
  },
};
