import {
  Container, Divider, Grid, Header, Icon, List, Segment, Image,
} from 'semantic-ui-react';
// import Image from 'next/image';
import classNames from 'classnames';
import ANSILogo from '../images/ANABTransparentBkgr.png';
import LANAfooter from '../images/LANAfooter.png';

const gray = { backgroundColor: '#ccc', color: 'black' };

export default function Footer({ exp = false }) {
  return (
    <Segment className={classNames('footer', { exp })} vertical>
      <Container>
        <Grid divided>
          <Grid.Column width={3}>
            <List link>
              <List.Item as="a" href="/about">
                About
              </List.Item>
              <List.Item as="a" href="/announcements">
                Announcements
              </List.Item>
              <List.Item as="a" href="/sponsors/become-a-sponsor">
                Become a Sponsor
              </List.Item>
              <List.Item as="a" href="/therapists">
                Find a Specialist
              </List.Item>
              <List.Item
                as="a"
                target="_blank"
                href="/uploads/LANA Policy & Procedures Manual 2022.pdf"
              >
                Policy and Procedures
              </List.Item>
              <List.Item as="a" href="/contact">
                Contact Us
              </List.Item>
              <List.Item as="a" href="/faq">
                FAQ
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={4}>
            <List link>
              <List.Item as="a" href="/disclaimers">
                Disclaimers
              </List.Item>
              <List.Item as="a" href="/privacy-policy">
                Privacy Policy
              </List.Item>
              <List.Item as="a" href="/trademark-policy">
                Trademark Policy
              </List.Item>
              <List.Item as="a" href="/statement-of-non-discrimination">
                Statement of Non-discrimination
              </List.Item>
              <List.Item as="a" href="/statement-of-impartiality">
                Statement of Impartiality
              </List.Item>
              <List.Item as="a" href="/records-management-policy">
                Records Management Policy
              </List.Item>
              <List.Item as="a" href="/uploads/56949690-0bea-4255-ba09-fdcb6c9a7a0a.pdf">
                LANA Contributor Agreement
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={2} className="flex">
            <Image centered size="small" src={ANSILogo.src} width={113} />
            <p />
          </Grid.Column>

          <Grid.Column width={6}>
            <Grid stackable>
              <Grid.Column width={16} id="footerAddress">
                <br />
                <Header
                  as="h4"
                  style={{ textAlign: 'center', padding: '10px' }}
                  content="General Contact  and Applications"
                />
                <List>
                  <List.Item style={{ textAlign: 'center', marginBottom: '10px' }}>
                    LANA
                    <br />
                    PO Box 16183
                    <br />
                    St. Louis, MO 63105
                  </List.Item>
                  <List.Item style={{ textAlign: 'center', marginBottom: '10px' }}>
                    773-756-8971
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as="h4" style={{ padding: '10px' }} content="" />
                <List>
                  <List.Item as="a" style={{ marginBottom: '10px' }} />
                </List>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="center" width={1}>
            <Header as="h4" />
            <List link id="footerSocial">
              <List.Item
                as="a"
                href="https://twitter.com/LANA_lymphology"
                target="_blank"
                rel="noreferrer"
                style={{ marginBottom: '14px' }}
              >
                <Icon size="big" name="twitter" />
              </List.Item>
              <List.Item
                as="a"
                href="https://www.facebook.com/LymphologyAssociationofNorthAmerica"
                target="_blank"
                rel="noreferrer"
                style={{ marginBottom: '14px' }}
              >
                <Icon size="big" name="facebook" />
              </List.Item>
              <List.Item
                as="a"
                href="https://www.linkedin.com/company/lana-lymphology-association-of-north-america/"
                target="_blank"
                rel="noreferrer"
                style={{ marginBottom: '14px' }}
              >
                <Icon size="big" name="linkedin" />
              </List.Item>
              <List.Item as="a" href="/contact" style={{ marginBottom: '14px' }}>
                <Icon size="big" name="mail" />
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>

        <Divider section style={gray} />

        <Image centered size="small" src={LANAfooter.src} width={150} />
        <Container textAlign="center" style={{ color: 'gray', marginTop: '2em' }}>
          <span dangerouslySetInnerHTML={{ __html: '&copy;' }} /> Copyright Lymphology Association
          of North America
        </Container>
      </Container>
    </Segment>
  );
}
